.add-user-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.add-user-container form {
    width: 52%;
    min-width: 620px;
    padding: 40px 30px;
    background: var(--light-grey);
    border-radius: 10px;
}

.add-user-container form .title {
    text-align: center;
}

.add-user-container .form-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
}

.add-user-container .form-container .right-container {
    margin-left: 5%;
}

.add-user-container .form-field {
    margin-bottom: 12px;
    position: relative;
}

.add-user-container .form-field label {
    font-size: 15px;
}

.add-user-container .form-field input[type=text] {
    font-size: 15px;
}

.add-user-container .edit-credits,
.add-user-container .save-credits,
.add-user-container .cancel-credits {
    border: none;
    background: var(--red);
    font-size: 14px;
    white-space: nowrap;
    color: white;
    height: fit-content;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 16px;
}

.add-user-container .cancel-credits {
    background: #eaeaea;
    color: var(--text-color);
    margin-left: 8px;
}

.add-user-container .edit-credits:hover,
.add-user-container .save-credits:hover {
    background: var(--hover-color);
}

.add-user-container .cancel-credits:hover {
    background: #d9d9d9;
}

.add-user-container .bottom-container {
    padding: 0 40px;
}

.add-user-container .btn-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.add-user-container .btn-container .submit-btn,
.add-user-container .btn-container .delete-btn {
    font-size: 14px !important;
    width: auto;
    background-color: var(--red);
    padding: 10px 20px;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    margin: auto 4px;
}

.add-user-container .btn-container .submit-btn:hover,
.add-user-container .btn-container .delete-btn:hover {
    background-color: var(--hover-color);
}

.add-user-container .bottom-container textarea {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {

    .add-user-container form {
        width: 100%;
        padding: 40px 10px;
    }

    .add-user-container .form-container {
        width: 100%;
        display: grid;
        /* justify-content: space-between; */
        justify-content: center;
        padding-bottom: 0;
    }

    .add-user-container .form-container .right-container {
        margin-left: 0;
    }

    .box-container {
        display: grid;
    }

    .admin-dashboard-container .col-3 {
        width: 100% !important;
    }

    .add-user-container form {
        min-width: auto;
    }

    .add-user-container .btn-container {
        display: grid;
    }

    .add-user-container .btn-container .submit-btn,
    .add-user-container .btn-container .delete-btn {
        margin: 4px auto;
    }

}
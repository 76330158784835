.password-fields {
    width: 480px;
    max-width: 94%;
    min-width: 180px;
    padding: 40px 80px;
    background: var(--light-grey);
    border-radius: 10px;
    margin: 26px auto;
    display: grid;
}

.password-fields .change-password-btn {
    background: var(--red);
    padding: 10px 20px;
    border-radius: 8px;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 500;
    margin: 10px auto;

}

.password-fields .change-password-btn:hover {
    background: var(--hover-color);

}

@media screen and (max-width:600px) {
    .password-fields {
        padding: 30px 40px;
    }
}
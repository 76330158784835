.bar-chart {
    min-width: 600px;
}


.data-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-box {
    padding: 12px;
    border-radius: 10px;
    margin-right: 6px;
    background: var(--red);
    color: var(--light-grey);
    text-align: center;
    min-width: 100px;
}

.data-box p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
}

.data-box h3 {
    font-weight: 600;
}

/* DailyReport.css */

/* Global styles for the report container */
.report-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
    padding-bottom: 10%;
}

.report-container h2 {
    display: flex;
    justify-content: space-between;
}

.section {
    margin-bottom: 32px;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.section-header h3 {
    margin: 0;
}

.add-btn {
    background-color: var(--red);
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.add-btn:hover {
    background-color: var(--hover-color);
}

.report-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.report-table th,
.report-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.report-table tr:hover {
    background-color: #f8f8f8;
}

.report-table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.total-label {
    font-weight: bold;
    text-align: right;
    padding-right: 10px;
}

.total-value {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    border: 1px solid #ddd;
}

.date-input {
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 14px;
    border: 1px solid gray;
}

/* Responsive styles */
@media (max-width: 768px) {
    .section-header {
        /* flex-direction: column; */
        /* align-items: flex-start; */
    }

    .add-btn {
        /* margin-top: 8px; */
        font-size: 14px ;
    }

    .report-table th,
    .report-table td {
        font-size: 12px;
        padding: 6px;
    }

    .date-input {
        /* width: 100%; */
        margin-bottom: 16px;
    }
}

@media (max-width: 576px) {
    .report-container {
        padding: 8px;
    }

    .report-container h2 {
        flex-direction: column;
        text-align: center;
    }

    .report-table th,
    .report-table td {
        font-size: 10px;
        padding: 4px;
    }

    .add-btn {
        /* width: 100%; */
        font-weight: 500;
        padding: 6px 12px;
    }

    .section-header {
        margin-bottom: 8px;
    }

    .date-input {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        margin-bottom: 12px;
    }
}
.create-item-container {
    background: var(--light-grey);
    border-radius: 8px;
    padding: 16px;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.list-container {
    margin: 20px;
    padding: 20px;
}

.list-container .title {
    text-align: center;
    font-size: 36px;
}

.table-container {
    overflow-x: auto;
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    /* border-radius: 8px; */
    overflow: hidden;
}

th,
td {
    padding: 12px;
    text-align: center !important;
    border: 1px solid lightgray !important;
}

th {
    background-color: #f2f2f2;
}

tr {
    border-top: 0.5px solid lightgray !important;
    border-bottom: 0.5px solid lightgray !important;
}

tr:hover {
    background-color: #f5f5f5;
}

.table-container .no-data-message {
    text-align: center;
}

.table-container .no-data-message p {
    margin: auto;
}

/* .table-container .services-container {

} */

.table-container .service-list {
    background: #ffe5e5;
    white-space: nowrap;
    margin: 5px;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    width: fit-content;
}

@media screen and (max-width: 768px) {

    .create-item-container {
        flex-direction: column;
        line-height: 3;
    }

    th,
    td {
        padding: 10px;
    }

    .list-container {
        margin: 20px;
        padding: 20px 0;
    }

    .table-container {
        padding: 20px 0;
    }
}

@media screen and (max-width: 480px) {

    th,
    td {
        padding: 8px;
    }
}
/* For charts Top Labels */
rect.MuiChartsLegend-mark {
    rx: 25;
}

/* Statistics.css */

.statistics-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.form-control-wrapper {
    padding: 12px 24px;
    background: var(--light-grey);
    border-radius: 16px;
    margin: 16px 0;
    width: 100%;
    max-width: 600px;
}

.select-wrapper {
    width: 100%;
    max-width: 400px;
    margin: 16px 0;
}

.chart-container {
    width: 100%;
    padding: 16px;
}

.yearly-chart-container {
    width: 48%;
    margin-bottom: 20px;
    background: var(--light-grey);
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    min-width: fit-content;
}

.chart-container {
    margin-bottom: 20px;
    background: var(--light-grey);
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
}

.statistics-table-container {
    margin: 10px 14px;
    /* border: 1px solid; */
    background: var(--light-grey);
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    width: fit-content;
}

.comparison-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.no-comparison-container {
    width: 96%;
}

.stats-instance-container {
    margin: 8px 10px;
}

.comparison-container .stats-instance-container {
    width: 48% !important;
    margin: 8px !important;
}


@media (max-width: 768px) {

    .form-control-wrapper,
    .select-wrapper,
    .chart-container {
        width: 90%;
    }

    .form-control-wrapper {
        padding: 12px;
    }

    .chart-container {
        padding: 8px;
    }
}

@media (max-width: 576px) {

    .stats-instance-container {
        margin: 8px 0px;
    }

    .statistics-table-container {
        margin: 10px 0px;
        font-size: 12px;
    }

    .statistics-table-container th,
    .statistics-table-container td {
        padding: 5px !important;
    }

    .form-control-wrapper {
        margin: 8px 0;
    }

    .select-wrapper {
        margin: 8px 0;
    }

    .chart-container {
        margin: 8px 0;
        width: fit-content;
    }

    .yearly-main-chart-container {
        width: fit-content;
    }

    .yearly-chart-container {
        width: 92%;
        margin: 10px 0;
        width: fit-content;
    }
}
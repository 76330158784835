.box-container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 250px 250px 250px;
    gap: 20px;
    padding: 40px;
    justify-content: center;
    place-items: center;
}

.box-container .box-button {
    background-color: var(--hover-color);
    border: 1px solid #ccc;
    max-width: 250px;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
    align-content: center;
    color: #fff;
    border-radius: 16px;
    transition: .3s ease;
    position: relative;

}

.box-container .box-button:hover {
    cursor: pointer;
    transform: scale(1.02);
    background-color: var(--red);
}

.box-container .box-button span {
    font-weight: 700;
    font-size: 20px;
}

.box-button svg {
    position: absolute;
    bottom: -15px;
    right: -3px;
    font-size: 12rem;
    opacity: 0.3;
    z-index: 0;
}


@media (max-width: 768px) {
    .box-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .box-container {
        grid-template-columns: 1fr;
    }
}
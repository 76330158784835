.management-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 5%;
}

.management-container .col-3 {
    background: #f8f8f8;
    height: 320px;
    border-radius: 10px;
    padding: 12px;
    position: relative;
    min-width: 240px;
}

.management-container .title-container {
    text-align: center;
    background-color: var(--red);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* border-radius: 10px 10px 0 0; */
    border-radius: 10px;
    padding: 8px;
}

.management-container .title-container .title {
    color: white;
    margin: 0;
    font-weight: 600;
    white-space: nowrap;
}

.management-container .btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
}

.management-container .btn-container .btn {
    color: var(--text-color);
    padding: 8px;
    font-weight: 600;
    width: 100%;
}

.management-container .btn-container .btn:hover {
    /* background-color: #ececec; */
    background-color: #e3edff;
    color: var(--hover-color);
}

/* {
     display: flex;
     justify-content: center;
     padding: 20px;
 } */

.management-container form {
    width: 52%;
    min-width: 620px;
    padding: 40px 30px;
    background: var(--light-grey);
    border-radius: 10px;
}

.management-container form .title {
    text-align: center;
}

.management-container .form-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
}

.management-container .form-container .right-container {
    margin-left: 5%;
}

.management-container .form-field {
    margin-bottom: 12px;
    position: relative;
}

.management-container .form-field label {
    font-size: 15px;
}

.management-container .form-field input[type=text] {
    font-size: 15px;
}

.management-container .edit-credits,
.management-container .save-credits,
.management-container .cancel-credits {
    border: none;
    background: var(--red);
    font-size: 14px;
    white-space: nowrap;
    color: white;
    height: fit-content;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 16px;
}

.management-container .cancel-credits {
    background: #eaeaea;
    color: var(--text-color);
    margin-left: 8px;
}

.management-container .edit-credits:hover,
.management-container .save-credits:hover {
    background: var(--hover-color);
}

.management-container .cancel-credits:hover {
    background: #d9d9d9;
}

.management-container .bottom-container {
    padding: 0 40px;
}

.management-container .btn-container .submit-btn,
.management-container .btn-container .delete-btn {
    font-size: 14px !important;
    width: auto;
    background-color: var(--red);
    padding: 10px 20px;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    margin: auto 4px;
}

.management-container .btn-container .submit-btn:hover,
.management-container .btn-container .delete-btn:hover {
    background-color: var(--hover-color);
}

.management-container .bottom-container textarea {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {

    .management-container form {
        width: 100%;
        padding: 40px 10px;
    }

    .management-container .form-container {
        width: 100%;
        display: grid;
        /* justify-content: space-between; */
        justify-content: center;
        padding-bottom: 0;
    }

    .management-container .form-container .right-container {
        margin-left: 0;
    }

    .management-container {
        display: grid;
    }

    .col-3 {
        width: 100% !important;
    }

    .management-container form {
        min-width: auto;
    }

    .btn-container {
        display: grid;
    }

    .btn-container .submit-btn,
    .btn-container .delete-btn {
        margin: 4px auto;
    }

}